$active: #007aff;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
  overscroll-behavior-x: none;
}
html {
  height: 100%;
  overflow: hidden;
  position: relative;
}
body {
  height: 100%;
  overflow: auto;
  position: relative;
  font-family: "Open Sans", sans-serif;
  color: #333333;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.desctop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 32px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
}

.center {
  text-align: center;
  display: inline-block;
  font-weight: 700;
}

.justify-space-around {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

button.MuiButtonBase-root,
span.MuiButton-contained {
  height: 54px;
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
}

button.MuiButton-outlinedSecondary {
  color: #ff3b30;
  border: 1px solid #ff3b30;
}

.iframeContent {
  width: 100%;
  height: calc(100% - 56px);
  border: none;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.iframeIdentification {
  width: 100%;
  height: calc(100% - 56px);
  border: none;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.clientVideoIframe {
  opacity: 0;
  z-index: -1;
}

.link {
  color: $active;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.uploadPhoto {
  margin-bottom: 8px;

  &__input {
    display: none;
  }

  span.MuiButton-contained {
    background-color: $active;
  }

  span.orangeColor {
    background-color: #ff845e;
    color: #fff;
  }
}

.container {
  max-width: 100%;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  height: 100%;
}

.title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.authorization {
  background: #fff;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;

  &__logo {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 34px;
  }

  &__form {
    text-align: center;
  }

  &__link {
    color: $active;
    text-decoration: none;
    border-bottom: 1px solid $active;
    display: inline-block;
    margin-bottom: 32px;
  }

  &__code {
    font-weight: 600;
  }

  &__clear {
    .MuiButtonBase-root {
      padding: 0;
      margin-bottom: 18px;
    }
  }

  &__pinInput {
    .MuiInputBase-input {
      text-align: center;
    }
  }

  &__count {
    color: #757575;
    font-size: 15px;
  }

  .MuiFormHelperText-root {
    text-align: center;
    color: #333333;
    font-size: 16px;
  }

  .MuiFormControl-root {
    margin-bottom: 22px;
  }
}

.alert_text {
  margin: 30px 0px;
  color: #ff3b30;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}

.btnRow {
  // position: fixed;
  bottom: 16px;
  left: 32px;
  right: 32px;
  max-width: 296px;
  margin: auto;

  .MuiButton-contained.Mui-disabled,
  .MuiButton-contained:hover.Mui-disabled {
    background-color: #e0e0e0;
  }
}

.back {
  display: flex;
  align-items: center;
  color: $active;
  font-size: 17px;
  margin-bottom: 32px;
}

button.cancel {
  color: $active;
  margin-bottom: 12px;
  font-size: 16px;
  height: auto;
  font-weight: 400;
  text-transform: none;
}

.content {
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    z-index: -1;
    overflow: hidden;
    height: 350px;
    padding-left: 10px;
    padding-right: 10px;

    &__img {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    section {
      width: 100%;
      height: fit-content;
    }
  }

  .title {
    max-width: 296px;
    text-align: center;
    margin: 0 auto;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    &Title {
      font-weight: 600;
    }
  }

  .cancel {
    position: absolute;
    left: 12px;
  }

  .btnRow {
    button {
      &:nth-of-type(2) {
        margin-top: 12px;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .desc {
    text-align: center;
    color: #757575;
    font-size: 18px;
  }
}

.stepIndividual {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .cancel {
    position: static;
  }

  .title {
    margin-top: 12px;
  }

  .icon {
    margin-bottom: 46px;
    position: static;
    height: auto;
    align-items: flex-start;
    margin-top: 16px;
    transform: translateY(0);
  }

  &__ul {
    padding: 0;
    flex-wrap: wrap;
    max-width: 256px;
    margin: auto;
  }

  &__li {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 18px 32px;
    font-size: 18px;
    font-weight: 600;
  }

  &__icon {
    margin-right: 16px;
  }
}

.photoFace {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
  }

  .cancel {
    position: static;
  }

  &__title {
    font-weight: 700;
  }

  &__desc {
    height: 64px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 17px;
  }

  .MuiButton-containedPrimary {
    background-color: #65c466;
  }

  &__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 0 -130px;
    position: absolute;
    box-sizing: border-box;
  }
}

.passwordFace {
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .cancel {
    position: static;
  }
}

.passwordData {
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // overflow-y: scroll;

  .cancel {
    position: static;
  }

  &__form {
    margin-top: 12px;
    padding: 16px 16px 0;

    .MuiFormControl-root {
      margin-bottom: 26px;
    }
  }

  .btnRow {
    position: static;
    margin-bottom: 26px;
  }
}

.personalNumber {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    margin-bottom: 30px;
  }

  &__icon {
    border: 1px solid rgba(155, 155, 155, 0.54);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .btnRow {
    position: static;
    width: 100%;
  }
}

.secretWord {
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    &Title {
      font-weight: 600;
    }
  }

  &__desc {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  &__nobody {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 2rem;
  }

  .cancel {
    position: absolute;
    left: 10px;
  }
}

.registrationEnd {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .cancel {
    position: static;
  }

  &__list {
    padding: 0;
    padding-left: 16px;

    li {
      list-style: none;
      margin-bottom: 16px;

      a {
        color: $active;
      }
    }
  }
}

.videoCall {
  height: 100%;
  padding-top: 16px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__mode {
    color: #757575;
    font-weight: 600;
    text-align: center;
    margin-top: 2.5rem;
    font-size: 17px;
  }

  &__round {
    position: relative;
    margin: auto;
    text-align: center;
    width: 90px;
    height: 90px;
    background-color: #2196f3;
    border-radius: 50%;
    animation: radial-pulse 1.3s infinite;

    &::after {
      content: "";
      position: absolute;
      width: 90px;
      height: 90px;
      background-color: #2196f3;
      border-radius: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 2;
      animation: radial-after 1.3s infinite;
    }
  }

  .icon {
    svg {
      width: 200px;
      height: 200px;
    }
  }

  &__btnRow {
    left: 0;
    right: 0;
    margin: 26px auto;
    text-align: center;
  }
}

.busyOperators {
  justify-content: flex-start !important;

  .icon {
    top: 50%;
  }

  .busyTitle,
  .title {
    font-size: 22px;
  }
}

.busyTitle {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  position: fixed;
  bottom: 54px;
  left: 16px;
  right: 16px;
  box-sizing: border-box;
}

@keyframes radial-pulse {
  0% {
    box-shadow: 0 0 0 0px #b3d7ff;
  }

  100% {
    box-shadow: 0 0 0 16px #b3d7ff;
  }
}

@keyframes radial-after {
  0% {
    box-shadow: 0 0 0 0px #59a8ff;
  }

  100% {
    box-shadow: 0 0 0 8px #59a8ff;
  }
}

.identification {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;

  &__desc {
    color: #757575;
    text-align: center;
    margin-bottom: 24px;
  }
}

.congratulate {
  .title {
    margin-top: 44px;
  }
}

.congratulate {
  &__desc {
    color: #333333;
    text-align: center;
    margin-bottom: 24px;
  }
}

.mobileMenu_wrapper {
  width: 100%;
  height: 56px;
  background-color: #fff;
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  bottom: 0;
  z-index: 99;
}

div.mobileMenu {
  width: 100%;
  max-width: 1000px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  background-color: #fff;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;

  button {
    text-decoration: none;
    color: #898989;
    width: 50%;

    &.Mui-selected {
      color: #2290e0;

      svg path {
        fill: #2290e0;
      }
    }

    .MuiTypography-root {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .MuiListItemText-root {
    margin: 0;
    margin-top: -6px;
  }

  .MuiListItem-button {
    display: block;
    text-align: center;
    margin: auto;
  }

  .MuiListItemIcon-root {
    min-width: auto;
  }

  svg path {
    fill: #898989;
  }

  .MuiBottomNavigationAction-label {
    font-weight: 600;
    font-size: 12px;
  }
}

.clientVideoCall {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-bottom: 1.5rem;
  }

  &__item {
    min-height: 80px;
    width: 100%;
    max-width: 296px;
    background: #50bf34;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    margin-bottom: 1.6rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    box-sizing: border-box;

    &:nth-of-type(3) {
      background: #2196f3;
    }

    &:nth-of-type(4) {
      background: #7435c5;
    }
  }

  &__icon {
    margin-right: 16px;
    display: inline-block;
  }
}

.document {
  padding: 15px;
  position: relative;

  .title {
    text-align: left;
    font-size: 26px;
  }

  .MuiButtonBase-root {
    font-size: 14px;
    height: 48px;
  }
}

.tabPanel {
  padding: 24px 0;

  &__date {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.documentBlock {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 4px;
  position: relative;
  margin-bottom: 16px;

  &__icon {
    display: flex;
    margin-right: 20px;
  }

  &__endIcon {
    position: absolute;
    right: 24px;
  }

  &__price {
    font-weight: 600;
  }
}

.paymentOrder {
  z-index: 99;
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 72px;
  overflow-y: scroll;

  .title {
    font-size: 20px;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .btnRow {
    position: static;

    .MuiButton-containedSecondary {
      background-color: #ff3b30;
    }

    button {
      margin-bottom: 10px;
    }
  }
}

.document {
  padding-bottom: 54px;
}

.payment {
  margin-bottom: 22px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;

  &.borderNone {
    border-bottom: none;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    margin-bottom: 10px;
  }
}

.modal {
  &.confirm {
    .MuiPaper-root {
      max-width: 300px;
      padding-top: 0;
      padding-bottom: 10px;
    }

    .modal__actions {
      justify-content: flex-end;
      margin-top: 0;
      margin-right: 12px;
    }

    .modal__RadioGroup {
      .MuiButtonBase-root {
        max-width: 60px;
        height: auto;
        flex: 0 0 0;
      }
    }

    .MuiDialogTitle-root {
      padding-bottom: 4px;
    }

    .MuiButtonBase-root {
      max-width: 119px;
      height: 40px;
      width: 100%;
      font-size: 14px;
    }

    .MuiButton-containedPrimary {
      background: #31c85a;
    }

    .MuiButton-containedSecondary {
      background: #ff3b30;
    }

    .MuiDialogActions-spacing > :not(:first-child) {
      margin-left: 16px;
    }
  }

  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 26px;
  }

  &__text {
    margin: 0;
    color: #757575;
  }

  .MuiPaper-root {
    max-width: 405px;
    padding-top: 8px;
    padding-bottom: 24px;
    width: 100%;
  }

  .MuiDialogTitle-root {
    h2 {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .MuiButton-textPrimary {
    color: #333333;
    text-transform: none;
  }

  .modal__actions {
    justify-content: center;
    margin-top: 12px;
  }

  .MuiInputBase-root {
    margin-bottom: 24px;
  }

  .radioControl {
    .MuiFormLabel-root {
      font-size: 14px;
    }

    .MuiFormGroup-root {
      flex-direction: row;
    }
  }

  .link {
    &:hover {
      text-decoration: none;
    }
  }
}

.authSelect {
  background: linear-gradient(
    254.52deg,
    #03beb5 0.44%,
    #08a7ff 59.97%,
    #678cff 101.93%
  );
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    background-color: #fff;
    border-radius: 10px;
    max-width: 362px;
    width: 362px;
    margin: auto;
    padding: 1.5rem 1.2rem;
    text-align: center;
    box-sizing: border-box;
  }

  &__item {
    height: 80px;
    display: flex;
    font-weight: 600;
    font-size: 20px;
    background: #50bf34;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0.3rem 1rem;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__border {
    border: 1px solid;
    background: #fff;
  }

  &__icon {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-right: 1.2rem;

    .MuiSvgIcon-root {
      font-size: 28px;
    }
  }

  &__text {
    text-align: left;
  }

  .MuiFormControl-root {
    margin-bottom: 40px;
  }

  .btnRow {
    position: static;
  }

  .MuiButtonBase-root {
    max-width: 156px;
  }

  .MuiFormControl-root {
    text-align: center;
  }

  .MuiInputLabel-formControl {
    right: 0;
    transform: translate(0) scale(1);
    font-size: 13px;
  }

  .MuiInputBase-input {
    text-align: center;
  }
}

.iframe {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  &__content {
    width: 100%;
    height: calc(100% - 48px);
    border: none;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .iframe__exit {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    z-index: 99999999;
    background-color: #ffffff;

    .MuiListItemIcon-root {
      min-width: 36px;
    }
  }
}

.profile,
.clientVideoCall {
  padding-bottom: 106px;

  .exitRow {
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 56px;
    background-color: #fff;

    .MuiButtonBase-root {
      height: 46px;
    }
  }
}

.profile {
  padding: 16px;
  padding-bottom: 100px;
  max-width: 1000px;
  margin: 0px auto;

  .title {
    text-align: left;
    min-width: 100%;
    margin-bottom: 16px;
  }

  .passwordData__form {
    padding: 0;
  }

  &__content {
    text-align: center;
    margin: auto;
    border-bottom: 1px solid #c6c6c6;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .profile__avatar {
    width: 120px;
    height: 120px;
    font-size: 40px;
    margin: auto;
    margin-bottom: 16px;
  }

  &__name {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  .documentBlock__title {
    font-weight: 600;
  }

  .documentBlock__icon {
    min-width: 42px;
  }
}

.isFocused {
  transition: all 0.3s ease;

  .back {
    margin-bottom: 12px;
  }

  .authSelect__content {
    padding: 20px 32px;
  }

  .title {
    font-size: 16px;
    margin-bottom: 12px;
    transition: all 0.3s ease;
  }

  .authorization__logo {
    margin-bottom: 16px;

    svg,
    img {
      transition: all 0.3s ease;
      width: 86px;
      height: auto;
    }
  }
}

.isBlur {
  transition: all 0.3s ease;

  .title {
    transition: all 0.3s ease;
  }

  .authorization__logo {
    svg,
    img {
      transition: all 0.3s ease;
    }
  }
}

.cancel {
  .MuiPaper-root {
    padding-bottom: 0;
  }
}

.forbid {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  padding: 24px 16px;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: auto;

  &__text {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
}

.exitGuest {
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
  box-sizing: border-box;

  &__logo {
    max-width: 136px;
  }

  &__btn {
    max-width: 118px;

    .MuiListItemIcon-root {
      min-width: auto;
    }
  }
}

.solve {
  background-color: #fff;
  height: 100vh;
  padding: 3rem 2.2rem 2rem;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  &__logo {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__icon {
    width: 6rem;
    height: 6rem;
    background-color: #fafafa;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 120px;
    box-sizing: border-box;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__content {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333333;

    .bold {
      font-weight: 700;
      font-size: 24px;
    }
  }

  &__btn {
    position: fixed;
    bottom: 2rem;
    max-width: 296px;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    .MuiButtonBase-root {
      background-color: #31c85a;
    }

    .MuiButton-contained.Mui-disabled {
      background-color: #c4c4c4;
      color: #333333;
    }
  }
}

.signed {
  background: #ffffff;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 15px;
  color: #757575;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);

  &__fullName {
    font-weight: 700;
    margin-top: 3px;
  }

  &__signature {
    span {
      font-weight: 600;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 11px;
    margin-top: 6px;
  }

  &__icon {
    margin-right: 5px;
    display: flex;
  }
}

.signedContract {
  background: #ffffff;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 15px;
  color: #757575;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  z-index: 99;
  position: static;
  bottom: 12px;
  max-width: 358px;
  left: 10px;
  right: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: auto;

  &__item {
    border-bottom: 1px solid #c6c6c6;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__label {
    padding-bottom: 4px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .signed__signature {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.documentIframe {
  border: none;
  width: 100%;
  box-sizing: border-box;
  position: static;
}

.contractInfo {
  .btnRow {
    position: static;
    margin-bottom: 48px;
    text-align: center;

    .MuiFormControlLabel-root {
      margin: 0;
      margin-bottom: 4px;
    }

    button.MuiButtonBase-root {
      margin-bottom: 8px;
    }
  }
}

.contractInfo {
  z-index: 99;
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px;
  // padding-right: 0;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}

.contractInfo_wrapper {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto;
}

.contractWrapper {
  padding: 10px;
  word-break: break-all;
}

.btnRow {
  .MuiButton-containedSecondary {
    background-color: #ff3b30;
  }

  .MuiButton-containedPrimary {
    background-color: #31c85a;
  }
}

.empty {
  text-align: center;
  margin: auto;
  padding-right: 2rem;
  padding-left: 2rem;
  box-sizing: border-box;

  &__title {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    color: #7c7c7c;
  }
}

.paymentAccount {
  .MuiFormControl-root {
    margin-bottom: 16px;

    .MuiInputBase-input.Mui-disabled {
      color: #333333;
    }

    .MuiFormLabel-root.Mui-disabled {
      color: #757575;
    }

    .MuiInput-underline.Mui-disabled:before {
      border-bottom: 1px solid #c6c6c6;
    }
  }
}

.langGroup {
  bottom: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;

  .MuiButtonBase-root {
    height: 32px;
    color: #dadada;
    font-size: 18px;
    font-weight: 600;

    &.active {
      color: #ffffff;
    }
  }
}

.prevButton.MuiIconButton-root {
  position: absolute;
  left: 0;
  top: 8px;
}

.error-permission-text {
  color: #222222;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  padding: 0 26px;
  text-align: center;
}

.error-permission-template {
  display: flex;
  align-items: flex-start;
  padding: 8px 16px 8px 12px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.72);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-top: 8px;

  svg {
    flex: 0 0 16px;
    margin-top: 0.1em;
    margin-right: 12px;
  }
}

.error-permission-list {
  padding: 8px;
  margin: 26px;
  text-align: left;
  list-style: none;
  counter-reset: order;

  li {
    list-style: none;
    position: relative;
    display: flex;
    align-items: flex-start;
    counter-increment: order;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;

    &::not(:last-of-type) {
      padding-bottom: 20px;
    }

    &::before {
      content: counter(order);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      border: 1px solid lightgray;
      border-radius: 50%;
      margin-right: 20px;
      font-size: 16px;
      line-height: 1;
      background-color: #fff;
      box-shadow: 0 0 0 4px #fff;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 12px;
      width: 1px;
      height: 100%;
      background-color: lightgray;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      margin-top: 8px;
      border-radius: 8px;
      border: 1px solid lightgray;
    }
  }
}

*,
:after,
:before {
  box-sizing: border-box;
}

@media only screen and (max-width: 360px) {
  .authSelect__icon {
    margin-right: 1rem;
  }
  .solve {
    padding: 2rem 1.3rem;
  }
}

@media only screen and (max-width: 320px) {
  .solve {
    padding: 1rem;

    &__row {
      margin-top: 3rem;
      margin-bottom: 4rem;
    }

    &__icon {
      width: 96px;
      height: 96px;
    }
  }
}

//.authorization__form {
//  .btnRow {
//    button {
//      height: auto
//    }
//  }
//}

@media screen and (min-width: 600px) {
  .authorization__form {
    max-width: 480px;
    margin: 0 auto;
  }
}

@media screen and (max-height: 500px) {
  .content {
    .icon {
      svg {
        width: 100px;
        height: 100px;
      }
    }
  }
  .videoCall {
    &__round {
      position: relative;
      margin: auto;
      text-align: center;
      width: 45px;
      height: 45px;
      background-color: #2196f3;
      border-radius: 50%;
      animation: radial-pulse 1.3s infinite;

      &::after {
        content: "";
        position: absolute;
        width: 45px;
        height: 45px;
        background-color: #2196f3;
        border-radius: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 2;
        animation: radial-after 1.3s infinite;
      }
    }
  }
}

@media screen and (min-width: 968px) {
  .mobileMenu {
    max-width: 500px;
  }
  .document {
    max-width: 1000px;
    margin: 0 auto;
  }
  .passwordData {
    // max-width: 500px;
    // left: 50%;
    // transform: translateX(-50%);
    .MuiRadio-colorSecondary.Mui-checked {
      color: #007aff;
    }
  }
  .solve {
    left: 50%;
    transform: translateX(-50%);
  }
}

.empty-doc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}

.doc-preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 680px) {
  .authSelect__content {
    padding: 10px 1.2rem !important;
  }
}
.innView {
  position: relative;
  .back {
    position: fixed;
    left: 20px;
    top: 20px;
  }
  .MuiBackdrop-root {
    background-color: #fff;
  }
  .MuiPaper-elevation24 {
    box-shadow: none;
  }
  .MuiDialog-scrollPaper {
    align-items: flex-start;
    .MuiDialog-paperWidthSm {
      width: 400px;
    }
  }
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #019ae8 !important;
}
.child-documents {
  &__item {
    // margin-bottom: 15px;
    margin: 30px 15px;
  }
  .child-documents__name {
    font-weight: bold;
  }
  &__wrap {
    display: flex;
    align-content: center;
    svg {
      margin-left: 10px;
    }
  }
  &__html {
    margin-top: 10px;
  }
}
